/*
 * Sidebar
 */

.sidebar {
  /* position: fixed;
  top: 0;
  bottom: 0;
  left: 0; */
  z-index: 100; /* Behind the navbar */
  padding: 64px 0 0; /* Height of navbar */
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
}

.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 48px);
  padding-top: 0.5rem;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sidebar-sticky {
    position: -webkit-sticky;
    position: sticky;
  }
}

.sidebar .nav-link {
  font-weight: 500;
  color: #333;
}

.sidebar .nav-link .feather,
.sidebar .nav-link .svg-inline--fa {
  margin-right: 4px;
  color: #999;
}

.sidebar .nav-link.active {
  color: var(--bs-primary);
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather,
.sidebar .nav-link:hover .svg-inline--fa,
.sidebar .nav-link.active .svg-inline--fa {
  color: inherit;
}

.sidebar-heading {
  font-size: 0.75rem;
  text-transform: uppercase;
}
