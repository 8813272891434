.react-bootstrap-table table {
    table-layout: auto;
}

.react-bootstrap-table th[data-row-selection],.selection-cell{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 25px;
    border: none;

}