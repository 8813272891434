.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.link-button {
  color: white;
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: bold;
  display: inline;
  margin: 0;
  padding: 0;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.custom-file-label::after {
  content: 'Seleccionar';
}

#uploadedFile.form-control:disabled,
.form-control[readonly] {
  opacity: 0;
}

.custom-label-disabled {
  background-color: #e9ecef;
}

.logo-container {
  filter: brightness(0) invert(1);
}

.tab-content {
  border-bottom: 1px solid #dee2e6;
  border-left: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
  padding: 1em;
}

.react-toast-notifications__container {
  /* fix for toast always on top */
  z-index: 9999 !important;
  /* better contrast while modal is open */
  filter: drop-shadow(0px 0px 10px #0007);
}

.connector-card {
  .connector-status {
    position: absolute;
    top: 1em;
    right: 1em;
  }
}

.file-display {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 1rem;
  gap: 0.5rem;

  .audio {
    border-radius: 2rem;
  }

  .file-display-image {
    position: relative;
    flex: 1 1 100px;
    width: fit-content;
    order: 2;

    img {
      width: 8rem;
      height: 12rem;
      object-fit: cover;
      border-radius: 1rem;
      background: black;
      filter: drop-shadow(0 0 5px #0003);
    }

    > button {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      background: none;
      background-color: black;
      outline: none;
      border: none;
      color: white;
      text-transform: uppercase;
      cursor: pointer;

      &:hover {
        box-shadow: 0 0 0.5rem 0.1rem rgba(0, 0, 0, 0.2);
      }
    }
  }

  button.file {
    color: blue;
    background: none;
    border: none;
    outline: none;
    text-align: start;
    cursor: pointer;

    &:hover {
      color: darkmagenta;
    }
  }

  .file-display-audio {
    order: 1;
    flex: 1 1;
  }

  .file-display-default {
    button {
      order: 3;
      flex: 1 1;
    }
  }
}
