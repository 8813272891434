/*
 * Remito
 */

 @media print {
  @page {
    margin: 0mm;
  }
  html, body {
    size: A4;
    width: 210mm;
    height: 282mm;
    margin: 0mm;
    margin-bottom: 40mm;
    background: #FFF;
  }
}

.remito-container {
  font-size: 17px;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 5mm;
  margin-top: 40px;
}

.remito {
  position: absolute;
  top: 465px;
  left: 80px;
  max-width: 90%;
  min-width: 80%;
}

@page {
  margin: 0.6in 0.7in 1.5in 0.3in;
  margin-bottom: 40mm;
  max-width: 80%;
}

.datos-cabecera { top: 290px; left: 80px; position: absolute; }

.cabecera-fecha { top: 120px; right: 130px; position: absolute; margin-bottom: 0px }

page[size="A4"] {
  background: white;
  width: 21cm;
  height: 29.7cm;
  display: block;
  margin-bottom: 0mm;
  box-shadow: 0 0 0.5cm rgba(0,0,0,0.5);
}
