.wa-message-template-content {
  > :first-child {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }
  > *:not(:first-child) {
    border-top: 1px solid #ced4da;
  }
  > :last-child {
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }
}
